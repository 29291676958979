// import type { RecommendationSectionProp } from "~/api/get_inkusion_data.server";
import { Link } from "@remix-run/react";
// import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import theme from "~/theme/default";
import { classNames } from "~/utils";
// import { Section } from "~/layout/section";
import heroImage from "~/assets/images/img-hero-start.png";
import dotsBackground from "~/assets/images/inkusion/bg-dot-pattern-lightgray.png";

export const InfoAboutRu = ({ body, title, slug }: any) => {
  const { button } = theme;
  const { base, color } = button;

  return (
    <div
      className="relative"
      style={{
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: "100%",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={`section-wrapper`}>
        <div className="grid gap-4 lg:grid-cols-2">
          <div className="flex flex-col  lg:mt-14">
            {/* className="sm:text-3xl sm:leading-none md:text-4xl" */}
            <h3 className="text-3xl font-bold md:max-w-lg md:text-4xl lg:text-[64px] lg:leading-[1.1]">
              {title}
            </h3>

            <div className="mt-4 flex flex-col md:max-w-[550px]">
              <div>
                <div
                  className="pb-4 text-base text-primary lg:pb-5"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                />
                <Link
                  to="/search/main"
                  //   type="button"
                  // target="_blank"
                  className={classNames(base, color.secondary, "px-9")}
                  //   rel="noreferrer"
                >
                  Medien entdecken
                </Link>
              </div>
            </div>
          </div>
          <div className="aspect-w-12 aspect-h-7 hidden md:flex lg:aspect-none lg:h-[580px] lg:w-[615px]">
            <img
              className="h-full w-full object-contain"
              src={heroImage}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <Section>
        <div className="h-0.5 bg-stone-200" />
      </Section> */}
    </div>
  );
};
