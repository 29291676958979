import { Link } from "@remix-run/react";
// import { GetScreenSize } from "~/utils";
import { ThemenList } from "~/shared";
// import type { IMedia } from "~/types";
import theme from "~/theme/default";
import { classNames } from "~/utils";

export function ThemenschwerpunkteList({ data }: { data: Array<any> }) {
  // const location = useLocation();

  const { button } = theme;
  const { base, color } = button;

  return (
    <div className="mt-5">
      <div className="section-wrapper flex justify-between">
        <h4 className="w-full text-center text-[24px] font-bold text-textColor-primary md:text-left">
          Aktuelle Themen
        </h4>
        {/* <Link
          to={"/themenschwerpunkte"}
          className={classNames(
            base,
            "rounded-none border-b border-b-textColor-primary text-sm text-textColor-primary hover:border-b-btnColor-secondary"
          )}
          // onClick={() => setWeiterFilter(false)}
        >
          Mehr anzeigen
        </Link> */}
      </div>

      <ThemenList data={data} />
      <div className="flex w-full justify-center">
        <Link
          to={"/themenschwerpunkte"}
          className={classNames(base, color.primary, "px-9")}
        >
          Mehr laden
        </Link>
      </div>
    </div>
  );
}
