import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

export default function Collapse({ name, content, defaultOpen = false }: any) {
  return (
    <Disclosure
      defaultOpen={defaultOpen}
      as="div"
      // key={section.id}
      className="border-b border-gray-200 py-3"
    >
      {({ open }) => (
        <>
          <h3 className="flow-root">
            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
              {name}
              <span className="ml-6 flex items-center">
                <ChevronUpIcon
                  className={`${
                    !open ? "rotate-180 transform" : ""
                  } h-8 w-8 text-secondary`}
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel>
            <div className="space-y-4">{content}</div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
