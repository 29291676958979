import { Disclosure, Transition } from "@headlessui/react";
import { GetScreenSize, getImageUrl } from "~/utils";

export const ThemenschwerpunkteDetails = ({
  picture,
  description,
  name,
  subtitle,
}: any) => {
  // console.log("pictures", picture);

  const hasSubTitle = !!subtitle;
  const isLongContent =
    GetScreenSize() === "small"
      ? `${description}`.length > 280
      : hasSubTitle
      ? `${description}`.length > 680
      : `${description}`.length > 880;

  return (
    <div className="relative">
      <div className="pb-8 pt-3 lg:pt-6 lg:pb-14">
        <h1 className="text-4xl font-bold text-textColor-primary lg:text-[64px]">
          {name}
        </h1>
      </div>
      <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:items-start lg:gap-24">
        <div className="aspect-w-8 aspect-h-5 relative block w-full">
          <img
            className="rounded-[20px] object-cover"
            // src={picture.url}
            src={
              picture
                ? getImageUrl(picture.file.url)
                : // getImageUrl(picture.file.url)
                  "should add fallback"
            }
            alt=""
          />
          {/* <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
              <div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" /> */}
        </div>
        <div className="relative mx-auto max-w-md sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="aspect-h-6">
            <Disclosure>
              {({ open }) => (
                <>
                  {!open && (
                    <div className={isLongContent ? "overflow-clip" : ""}>
                      <div
                        // style={{
                        //   height: "350px",
                        // }}
                        className="break-normal py-8 text-[14px] leading-[1.714] tracking-wide text-textColor-primary lg:py-4"
                        dangerouslySetInnerHTML={{
                          __html: isLongContent
                            ? //680 old app
                              description
                                .slice(0, hasSubTitle ? 680 : 880)
                                .concat("...")
                            : description,
                        }}
                      />
                    </div>
                  )}
                  <Transition
                    show={open}
                    enter="transition duration-1000 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="py-8 lg:py-4">
                      <div
                        className="overflow-hidden text-[14px] leading-[1.714] tracking-wide text-textColor-primary"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </Disclosure.Panel>
                  </Transition>
                  {isLongContent && (
                    <Disclosure.Button
                      // onClick={() => setVisibility(!hidden)}
                      className="focus-visible:ring-none flex w-full pb-1 text-base font-bold
                   text-textColor-navigation hover:text-primary focus:outline-none
                   focus-visible:ring"
                    >
                      {/* <ChevronUpIcon
                          className={`${
                            !open ? "rotate-180 transform" : ""
                          } h-7 w-7 text-secondary`}
                        /> */}
                      <span>
                        {!open ? "MEHR ANZEIGEN" : "WENIGER ANZEIGEN"}
                      </span>
                    </Disclosure.Button>
                  )}
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>
      <div className="">
        <p className="py-2 text-xs text-textColor-primary">
          ©{picture?.copyright || picture?.name}
        </p>
      </div>
    </div>
  );
};
