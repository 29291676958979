import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { classNames } from "~/utils";
import { CustomSelect } from "~/shared";
import { flattenDeep } from "lodash";
import { MainFilterTypes } from "~/types";
import { Link } from "@remix-run/react";
import theme from "~/theme/default";
import { CheckBox } from "~/shared";

// import { useFormContext, useIsSubmitting } from "remix-validated-form";
// import type { StateFieldsValue } from "./types";

// export const MySubmitButton = ({
//   name,
//   label,
// }: {
//   name: string;
//   label: string;
// }) => {
//   const isSubmitting = useIsSubmitting();
//   // const { isValid } = useFormContext();
//   // const disabled = isSubmitting || !isValid;
//   // const { button } = theme;
//    const { base, color } = button;
//   // console.log("disabled========", disabled);
//   return (
//     <button
//       type="submit"
//       // name={name}

//       // disabled={disabled}
//       className={classNames(
//         "px-6 text-base font-bold text-white",
//         base,
//         color.secondary
//       )}
//     >
//       {isSubmitting ? "Submitting..." : label}
//     </button>
//   );
// };

export const MainFilter = ({
  filterData,
  handleResetMainFilter,
  setFieldValue,
  fieldsValue,
  action,
  setMobileFiltersOpen,
  mobileFiltersOpen,
  setOpen,
}: {
  filterData: any;
  setFieldValue: any;
  handleResetMainFilter: any;
  fieldsValue: any;
  setOpen: any;
  action?: string;
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (open: boolean) => void;
}) => {
  // const { pathname } = useLocation();

  // const [searchParams] = useSearchParams();
  // const [isChecked, setDefaultChecked] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  // const selectRef = useRef(null);

  const [selectedSecondaryEducation, setSelectedSecondaryEducation] = useState<
    Array<{ name: string; id: number }>
  >(fieldsValue["secondaryEducationIds"] || []);
  const [curriculaSelected, setCurriculaSelected] = useState<
    Array<{ name: string; id: number }>
  >(fieldsValue["curriculumIds"] || []);
  const [selectAcademicYear, setSelectAcademicYear] = useState<
    Array<{ name: string; id: number }>
  >(fieldsValue["academicYearIds"] || []);
  const [filteredSubjectArea, setFilteredSubjectArea] = useState<
    Array<{ name: string; id: number }>
  >([]);
  const [, setSelectedId] = useState(0);
  // const [sortedItems, setSortedItem] = useState(filteredSubjectArea);
  // const [options, setOptions] = useState(filterData["curriculums"]);
  const { button } = theme;
  const { base, color } = button;

  const filterAcademicYearSearchValueBasedOnSelectedSecondaryEducation = () => {
    const final = selectedSecondaryEducation.map((cuu: any) => {
      return filterData[MainFilterTypes.ACADEMIC_YEARS].filter(
        (item: any) => cuu.id === item.secondaryEducationId
      );
    });

    return flattenDeep(final);
  };

  const filterSecondayEducationFieldValueBasedonState = () => {
    const final = curriculaSelected.map((cur: any) => {
      return filterData[MainFilterTypes.SECONDARY_EDUCATIONS].filter(
        (item: any) => cur.id === item.curriculumId
      );
    });

    return flattenDeep(final);
  };

  const handleResetModal = () => {
    setSelectedSecondaryEducation([]);
    setCurriculaSelected([]);
    setSelectAcademicYear([]);
    setFilteredSubjectArea([]);
    handleResetMainFilter("main");
  };

  useEffect(() => {
    const subjectAreaFiltered = () => {
      if (
        curriculaSelected.length > 0 &&
        !selectedSecondaryEducation.length &&
        !selectAcademicYear.length
      ) {
        const subjectAreaBasedOncurriculaSelected = filterData[
          MainFilterTypes.SUBJECT_AREAS
        ].filter((su: any) =>
          curriculaSelected.map((s) => s.id).includes(su.curriculumId)
        );
        console.log(
          "🚀 ~ file: main_filter.tsx:117 ~ subjectAreaFiltered ~ subjectAreaBasedOncurriculaSelected:",
          subjectAreaBasedOncurriculaSelected,
          filterData
        );
        return subjectAreaBasedOncurriculaSelected.filter((item: any) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (
        curriculaSelected.length > 0 &&
        selectedSecondaryEducation.length > 0 &&
        !selectAcademicYear.length
      ) {
        const subjectAreaBasedOnSecondaryEducationSelected = filterData[
          MainFilterTypes.SUBJECT_AREAS
          //@ts-ignore
        ].filter((se) =>
          selectedSecondaryEducation
            .map((s) => s.id)
            .includes(se.secondaryEducationId)
        );
        // console.log(
        //   "subjectAreaBasedOnSecondaryEducationSelected",
        //   subjectAreaBasedOnSecondaryEducationSelected
        // );
        //@ts-ignore
        return subjectAreaBasedOnSecondaryEducationSelected.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (selectAcademicYear.length) {
        const items = selectAcademicYear.length
          ? //@ts-ignore
            filterData[MainFilterTypes.SUBJECT_AREAS].filter((se) =>
              selectAcademicYear.map((s) => s.id).includes(se.academicYearId)
            )
          : filterData[MainFilterTypes.SUBJECT_AREAS];
        //@ts-ignore
        return items.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      //@ts-ignore
      return filterData[MainFilterTypes.SUBJECT_AREAS].filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    };
    const filteredSubjectArea = subjectAreaFiltered();
    if (filteredSubjectArea.length) {
      setFilteredSubjectArea(filteredSubjectArea);
    }
  }, [
    selectedSecondaryEducation,
    curriculaSelected,
    selectAcademicYear,
    filterData,
    searchQuery,
  ]);

  // the selectFields should be using for both mobile UI and Web.
  const selectFields = [
    {
      id: "curriculumIds",
      name: "curriculums",
      placeHolder: "Bundesland",
      value: curriculaSelected,
      onChange: setCurriculaSelected,
      disabled: false,
      options: filterData["curriculums"],
    },
    {
      id: "secondaryEducationIds",
      name: "secondaryEducations",
      value: selectedSecondaryEducation,
      placeHolder: "Schulbereich",
      onChange: setSelectedSecondaryEducation,
      disabled: !fieldsValue["curriculumIds"],
      options: filterSecondayEducationFieldValueBasedonState(),
    },
    {
      id: "academicYearIds",
      name: "academicYears",
      value: selectAcademicYear,
      placeHolder: "Schulstufe",
      onChange: setSelectAcademicYear,
      disabled:
        !fieldsValue["curriculumIds"] || !fieldsValue["secondaryEducationIds"],
      options: filterAcademicYearSearchValueBasedOnSelectedSecondaryEducation(),
    },
  ];

  const selectSubjectArea = fieldsValue[`subjectAreaIds`]
    ? fieldsValue[`subjectAreaIds`]
    : [];

  const moveToTop = (array: Array<{ name: string; id: number }>) => {
    // if (selectedItem === 0) return array;

    return selectSubjectArea.concat(
      array.filter(
        (item) =>
          !selectSubjectArea
            .map((selected: any) => selected.id)
            .includes(item.id)
      )
    );
  };

  const sortedItems = moveToTop(filteredSubjectArea);
  // console.log(
  //   'fieldsValue["curriculumIds"] &selectedSecondaryEducation',
  //   // fieldsValue[`subjectAreaIds`],
  //   filterSecondayEducationFieldValueBasedonState(),
  //   filterData
  // );

  return (
    <>
      {/* Mobile filter dialog */}
      <Transition.Root show={true} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40  md:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative  flex h-full w-full max-w-full flex-col overflow-hidden bg-slate-50 pt-4 pb-12 shadow-xl">
                <div className="mt-14 flex items-center justify-between pl-4 pr-5">
                  <h2 className="text-xl font-bold text-textColor-light">
                    Lehrplänen filter
                  </h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon
                      className="h-6 w-6 text-primary"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                {/* Filters */}
                <form className="px-3">
                  <h3 className="sr-only">Lehrplänen filter</h3>

                  {selectFields.map((select) => (
                    <div
                      // as="div"
                      key={select.id}
                      // className="px-2"
                    >
                      <div className="pt-3">
                        <div className="space-y-3">
                          <CustomSelect
                            isDisabled={select.disabled}
                            // components={{ MultiValueRemove: () => null }}
                            isMulti
                            isClearable
                            name={
                              select.id
                              // selectAcademicYear.length ? "academicYearIds" : undefined
                            }
                            value={
                              fieldsValue[select.id] &&
                              fieldsValue[select.id].length
                                ? fieldsValue[select.id]
                                : null
                            }
                            placeholder={select.placeHolder}
                            id={select.id}
                            //@ts-ignore
                            onChange={(
                              e: any,
                              selectedValues: any,
                              allSelectedValues: any
                            ) => {
                              select.onChange(allSelectedValues);
                              setFieldValue(e, selectedValues);
                            }}
                            options={select.options}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </form>
                <div className="flex w-full flex-col px-3">
                  <div className="flex w-full items-center justify-between py-4">
                    <div
                      className="flex w-full md:ml-0"
                      //max-w-[400px] border border-slate-200
                    >
                      <span className="sr-only">Search</span>
                      <div className="relative w-full text-black  focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-4 flex items-center">
                          <MagnifyingGlassIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          onChange={(e) => setSearchQuery(e.target.value)}
                          id="search-subjectArea"
                          value={searchQuery}
                          // name="search-subjectArea"
                          className="block h-full w-full rounded-md border border-slate-100 py-2 pl-12 pr-3 text-gray-900 placeholder-gray-300 shadow-[0_1px_3px_rgba(0,0,0,0.134479)] focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Themenfeld suchen …"
                          // type="search"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="px-3 text-sm text-textColor-light">
                    Themenfelder ausgewählt:{" "}
                    <span className="font-bold">{`${
                      filteredSubjectArea.length
                    } von ${
                      filterData[MainFilterTypes.SUBJECT_AREAS].length
                    }`}</span>
                  </p>
                </div>
                <fieldset className="overflow-auto px-3 md:h-[30vh]">
                  {filteredSubjectArea.length
                    ? sortedItems.map((subjectArea: any) => {
                        let checkBox = selectSubjectArea;
                        const currentValue =
                          checkBox && checkBox.length
                            ? checkBox.filter(
                                (item: { name: string; id: number }) =>
                                  item.id == subjectArea.id
                              )
                            : undefined;

                        return (
                          <div
                            className="mr-2 border-b border-[#D8D8D8]"
                            key={subjectArea.id}
                          >
                            <CheckBox
                              formFieldValue={{
                                name: subjectArea.name,
                                id: subjectArea.id,
                              }}
                              value={
                                currentValue && currentValue.length
                                  ? currentValue[0].id
                                  : undefined
                              }
                              setSelectedId={setSelectedId}
                              checked={!!(currentValue && currentValue.length)}
                              setFieldValue={setFieldValue}
                              label={
                                subjectArea.name === "subjectAreaIds"
                                  ? subjectArea.title
                                  : subjectArea.name
                              }
                              key={subjectArea.id}
                              id={`subjectAreaIds-${subjectArea.id}`}
                              name="subjectAreaIds"
                              subTitle="Hessen | Sekundarstufe 1 | Jahrgangsstufe 7"
                            />
                          </div>
                        );
                      })
                    : undefined}
                </fieldset>
                <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-[0_-4px_5px_rgba(0,0,0,0.070125)]">
                  <div className="flex justify-between px-2 py-4">
                    <button
                      type="button"
                      onClick={handleResetModal}
                      // replace
                      // to={`${pathname}?pag=${1}`}
                      className={classNames(
                        base,
                        "rounded-none border-b border-b-textColor-primary pb-1 text-sm hover:border-b-btnColor-secondary"
                      )}
                    >
                      Alle Filter löschen
                    </button>
                    {/* <MySubmitButton  label="Speichern" /> */}
                    <Link
                      to={action || "#"}
                      // name={name}

                      // disabled={false}
                      className={classNames(
                        "px-6 text-base font-bold text-white",
                        base,
                        color.secondary
                      )}
                    >
                      Speichern
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <main className="hidden h-full w-full  pt-8 md:flex md:flex-col md:pt-0">
        <section
          aria-labelledby="filter-heading"
          className=" h-full flex-col sm:px-6  lg:flex lg:px-20"
        >
          <h2 id="filter-heading" className="sr-only">
            Lehrplänen filter
          </h2>

          <div className="grid h-full grid-cols-1 gap-x-8 lg:grid-cols-4">
            {/* Filters */}
            <div className="hidden h-full border-r border-gray-100 pt-6 shadow-[10px_0_5px_-2px_rgba(233,233,233,0.3)] md:col-span-1 md:block ">
              <div className="py-3 pr-4">
                <CustomSelect
                  // components={{ MultiValueRemove: () => null }}
                  isMulti
                  isClearable
                  name="curriculumIds"
                  value={
                    fieldsValue["curriculumIds"] &&
                    fieldsValue["curriculumIds"].length
                      ? fieldsValue["curriculumIds"]
                      : null
                  }
                  placeholder="Bundesland"
                  id="curriculumIds"
                  //@ts-ignore
                  onChange={(
                    e: any,
                    selectedValues: any,
                    allSelectedValues: any
                  ) => {
                    setCurriculaSelected(allSelectedValues);
                    setFieldValue(e, selectedValues);
                  }}
                  options={filterData["curriculums"]}
                />
              </div>
              <div className="py-3 pr-4">
                <CustomSelect
                  // components={{ MultiValueRemove: () => null }}
                  isMulti
                  isClearable
                  isDisabled={!fieldsValue["curriculumIds"]}
                  name={"secondaryEducationIds"}
                  placeholder="Schulbereich"
                  id="secondaryEducationIds"
                  value={
                    fieldsValue["secondaryEducationIds"] &&
                    fieldsValue["secondaryEducationIds"].length
                      ? fieldsValue["secondaryEducationIds"]
                      : null
                  }
                  //@ts-ignore
                  onChange={(
                    e: any,
                    selectedValues: any,
                    allSelectedValues: any
                  ) => {
                    setSelectedSecondaryEducation(allSelectedValues);
                    setFieldValue(e, selectedValues);
                  }}
                  options={filterSecondayEducationFieldValueBasedonState()}
                />
              </div>
              <div className="py-3 pr-4">
                <CustomSelect
                  // components={{ MultiValueRemove: () => null }}
                  isMulti
                  isClearable
                  isDisabled={
                    !fieldsValue["curriculumIds"] ||
                    !fieldsValue["secondaryEducationIds"]
                  }
                  name={
                    "academicYearIds"
                    // selectAcademicYear.length ? "academicYearIds" : undefined
                  }
                  value={
                    fieldsValue["academicYearIds"] &&
                    fieldsValue["academicYearIds"].length
                      ? fieldsValue["academicYearIds"]
                      : null
                  }
                  placeholder="Schulstufe"
                  id={"academicYearIds"}
                  //@ts-ignore
                  onChange={(
                    e: any,
                    selectedValues: any,
                    allSelectedValues: any
                  ) => {
                    setSelectAcademicYear(allSelectedValues);
                    setFieldValue(e, selectedValues);
                  }}
                  options={filterAcademicYearSearchValueBasedOnSelectedSecondaryEducation()}
                />
              </div>
            </div>

            {/* Product grid */}
            <div className="mb-20 w-full lg:col-span-3">
              <div className="flex w-full items-center justify-between py-6">
                <p className="text-xl font-bold text-primary">Themenfelder</p>
                <div
                  className="flex md:ml-0 lg:w-[400px]"
                  //max-w-[400px] border border-slate-200
                >
                  <span className="sr-only">Search</span>
                  <div className="relative w-full text-black  focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-4 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      onChange={(e) => setSearchQuery(e.target.value)}
                      id="search-subjectArea"
                      value={searchQuery}
                      // name="search-subjectArea"
                      className="block h-full w-full rounded-md border border-slate-100 py-4 pl-12 pr-3 text-gray-900 placeholder-gray-300 shadow-[0_1px_3px_rgba(0,0,0,0.134479)] focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Themenfeld suchen …"
                      // type="search"
                    />
                  </div>
                </div>
              </div>
              <p className="text-xs text-textColor-light">
                Themenfelder angezeigt:{" "}
                <span className="font-bold">{`${
                  filteredSubjectArea.length
                } von ${
                  filterData[MainFilterTypes.SUBJECT_AREAS].length
                }`}</span>
              </p>
              <fieldset className="overflow-auto md:h-[50vh]">
                {filteredSubjectArea.length
                  ? sortedItems.map((subjectArea: any) => {
                      // console.log(
                      //   "🚀 ~ ?sortedItems.map ~ subjectArea:",
                      //   subjectArea
                      // );
                      let checkBox = selectSubjectArea;
                      const currentValue =
                        checkBox && checkBox.length
                          ? checkBox.filter(
                              (item: { name: string; id: number }) =>
                                item.id == subjectArea.id
                            )
                          : undefined;

                      return (
                        <div
                          className="mr-2 border-b border-[#D8D8D8]"
                          key={subjectArea.id}
                        >
                          <CheckBox
                            formFieldValue={{
                              name: subjectArea.name,
                              id: subjectArea.id,
                            }}
                            value={
                              currentValue && currentValue.length
                                ? currentValue[0].id
                                : undefined
                            }
                            setSelectedId={setSelectedId}
                            checked={!!(currentValue && currentValue.length)}
                            setFieldValue={setFieldValue}
                            label={
                              subjectArea.name === "subjectAreaIds"
                                ? subjectArea.title
                                : subjectArea.name
                            }
                            id={`subjectAreaIds-${subjectArea.id}`}
                            name="subjectAreaIds"
                            subTitle="Hessen | Sekundarstufe 1 | Jahrgangsstufe 7"
                          />
                        </div>
                      );
                    })
                  : undefined}
              </fieldset>
            </div>
          </div>
        </section>

        <div className="fixed bottom-0 left-0 right-0 z-10 bg-white shadow-[0_-4px_5px_rgba(0,0,0,0.070125)]">
          {/* <button
            type="button"
            className="-m-2 ml-4 p-2 text-primary hover:text-gray-500 sm:ml-6 lg:hidden"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <span className="sr-only">Filters</span>
            <FunnelIcon className="h-5 w-5" aria-hidden="true" />
          </button> */}
          <div className="flex justify-between px-20 py-4">
            <button
              type="button"
              onClick={handleResetModal}
              // replace
              // to={`${pathname}?pag=${1}`}
              className={classNames(
                base,
                "rounded-none border-b border-b-textColor-primary pb-1 text-sm hover:border-b-btnColor-secondary"
              )}
            >
              Alle Filter löschen
            </button>
            {/* <MySubmitButton  label="Speichern" /> */}
            <Link
              to={action || "#"}
              // name={name}

              // disabled={false}
              className={classNames(
                "px-6 text-base font-bold text-white",
                base,
                color.secondary
              )}
            >
              Speichern
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

// import type { ReactNode } from "react";
// import { Fragment } from "react";
// import { Dialog, Transition } from "@headlessui/react";
// import { XMarkIcon } from "@heroicons/react/24/outline";
// import { classNames } from "~/utils";

// type ModalProps = {
//   title?: string;
//   subtile?: string;
//   open: boolean;
//   setOpen?: (open: boolean) => void;
//   hasIcon?: boolean;
//   children?: ReactNode;
//   type?: "success" | "warning";
//   planDialgo?: boolean;
// };
// export default function Modal({
//   title,
//   // subtile,
//   open,
//   setOpen,
//   // hasIcon = false,
//   // type = "success",
//   children,
// }: // planDialgo = false,
// ModalProps) {
//   return (
//     <Transition.Root show={open} as={Fragment}>
//       {/* @ts-ignore */}
//       <Dialog
//         as="div"
//         static
//         className="relative z-10 overflow-y-auto"
//         open={open}
//         onClose={
//           typeof setOpen === "function" ? () => setOpen(false) : () => null
//         }
//       >
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <Dialog.Overlay
//             as="div"
//             className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
//             onClick={() =>
//               typeof setOpen === "function" ? setOpen(false) : null
//             }
//           />
//         </Transition.Child>

//         <div className="fixed inset-0 z-10 overflow-hidden">
//           <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 lg:p-4">
//             <div
//               style={{
//                 // backgroundImage:
//                 //   "radial-gradient(circle, rgba(245,234,214,1) 0%, rgba(242,242,242,1) 100%)",

//                 background:
//                   "linear-gradient(0deg, rgba(250,252,252,1) 0%, rgba(252,244,214,1) 100%)",
//                 // border-radius: '16px',
//                 boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
//                 backdropFilter: "blur(12.3px)",
//                 border: "rgba(254, 250, 179, 0.06)",
//               }}
//               className="overflow-hiddren relative flex h-auto w-full transform flex-col rounded-lg pt-5 pb-4 text-left shadow-xl transition-all sm:mt-8  md:max-w-9xl"
//             >
//               <div className="flex justify-between">
//                 <div className="flex items-baseline justify-between  pb-6 sm:px-6 lg:px-20">
//                   <h1 className="text-2xl font-bold tracking-tight text-gray-900">
//                     {title}
//                   </h1>

//                   <div className="flex items-center">
//                     <button
//                       type="button"
//                       className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
//                       onClick={() => undefined}
//                     >
//                       <span className="sr-only">Filters</span>
//                       <XMarkIcon className="h-5 w-5" aria-hidden="true" />
//                     </button>
//                   </div>
//                 </div>

//                 <div className="pr-5 sm:block lg:pr-20">
//                   <button
//                     type="button"
//                     className="z-10 flex w-full rounded-full bg-black
//             text-white hover:text-secondary
//             focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
//                     onClick={() =>
//                       typeof setOpen === "function" ? setOpen(false) : null
//                     }
//                   >
//                     <span className="sr-only">Close</span>
//                     <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//                   </button>
//                 </div>
//               </div>
//               <div className="mx-20 h-[1px] bg-default opacity-10"></div>
//               <div className="flex h-full flex-col py-4">{children}</div>
//             </div>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// }
