import { useEffect, useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
// import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { Disclosure, Transition } from "@headlessui/react";
// import { getArticleAreaHeightBasedOnArticleContent } from "./utils/get_article_height";
import { isFullScreen } from "./utils/is_full_Screen_document";
import { classNames, GetScreenSize, getImageUrl, dateFormat } from "~/utils";
import ArticleImage from "./article_image";
import { Fullscreen } from "./full_screen_btn";
import type { ArticleData, IMedia } from "~/types";
import {
  ArrowRightIcon,
  ArrowUpOnSquareIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import LockClosedIcon from "~/assets/icons/icon-lock-dark.svg";
// import moment from 'moment';
import useLocalStorageState from "use-local-storage-state";
import toast, { Toaster } from "react-hot-toast";
import { copyToClipBoard } from "~/utils";
import { getClientSideConfig } from "~/utils";

export const ArticleDescriptionLayout = ({
  pictures,
  description,
  name,
  id,
  authors,
  subtitle,
  publicationDate,
  link,
  restriction,
  firstPicture,
  embedUrl,
}: ArticleData) => {
  const [showImageGallery, toggleImageGallery] = useState(false);
  const [, setImageError] = useState(false);
  const [, setThunbnailError] = useState(false);
  const [isFav, setItemFav] = useState(false);
  const hasSubTitle = !!subtitle;
  const [favoriteList, setFavoriteList] = useLocalStorageState("favoriteList", {
    defaultValue: [],
  });

  // console.log(
  //   "🚀 ~ file: article_description_layout.tsx:53 ~ isItemFavorite ~ isItemFavorite:",
  //   firstPicture
  // );

  const addToFavorite = ({ name, firstPicture, id }: IMedia) => {
    if (!isFav) {
      setFavoriteList([
        // @ts-ignore
        ...favoriteList,
        // @ts-ignore
        {
          name,
          firstPicture,
          id,
        },
      ]);
    } else {
      // @ts-ignore
      const newItems = favoriteList.filter((item: any) => item.id !== id);
      if (!newItems) {
        setFavoriteList([]);
      } else {
        setFavoriteList(newItems);
      }
    }
  };
  const handleAddedTofavorite = () => {
    let favoriteItem = {
      name,
      firstPicture,

      id,
    };
    //@ts-ignore
    addToFavorite(favoriteItem, isFav);
  };
  const isLongContent =
    GetScreenSize() === "small"
      ? false // alwas false when is mobile screen
      : hasSubTitle
      ? `${description}`.length > 680
      : `${description}`.length > 880;
  const autorsLength = authors ? authors.length : 0;
  // const isLongTitle = `${name}`.length > 52;

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      if (showImageGallery) {
        //@ts-ignore
        ref.current.fullScreen();
      }
    }
  }, [showImageGallery]);

  useEffect(() => {
    const isItemFavorite = (id: number) => {
      const itemInFavorite = favoriteList.length
        ? favoriteList.find((item: any) => item.id === id)
        : undefined;
      if (itemInFavorite) {
        return true;
      }
      return false;
    };
    if (isItemFavorite(id)) {
      setItemFav(true);
    } else {
      setItemFav(false);
    }
  }, [favoriteList, id]);

  function handleScreenChange() {
    /*
      handles screen change events that the browser triggers e.g. esc key
    */
    const fullScreenElement = isFullScreen();
    if (!fullScreenElement) {
      toggleImageGallery(false);
    }
  }
  const onOpen = (text: string) => {
    return toast.success(text);
  };
  const handleCopyArticleToClipboard = (id: number, name: string) => {
    const isCopied = copyToClipBoard(
      `${getClientSideConfig.shareLinkUrl}/search/main/${id}/${name.replace(
        /\s/g,
        "-"
      )}}`.replace("#", "")
    );
    if (isCopied) {
      onOpen(
        "Der Link zu diesem Eintrag befindet sich nun in Deiner Zwischenablage. Du kannst ihn nun überall über Rechtsklick+Einfügen oder strg+v/cmd+v  einfügen."
      );
    }
    // setTimeout(() => {
    //   setFavOpen(false);
    // }, 9000);
  };
  return (
    <div className="relative">
      <div className="grid grid-cols-1 lg:mx-auto lg:grid-cols-2 lg:items-start lg:gap-24 ">
        <ArticleImage
          pictures={pictures}
          videoUrl={embedUrl}
          toggleImageGallery={() => toggleImageGallery(!showImageGallery)}
        />
        <div className="relative mx-auto max-w-md  px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          <div className="flex flex-row">
            <div
              className={classNames(
                "flex w-full items-center pt-5 lg:w-1/2 lg:pt-0",
                autorsLength > 3 ? "flex-col" : "flex-row"
              )}
            >
              <div className="mr-3 flex flex-shrink-0">
                {autorsLength > 0 &&
                  authors.map((item, index) => (
                    <img
                      className={classNames(
                        index === autorsLength - 1 ? "mr-0" : "mr-1",
                        "block h-14 w-14 rounded-full object-contain"
                      )}
                      key={item.id}
                      src={getImageUrl(item.avatar.url)}
                      alt={item.name}
                    />
                  ))}
              </div>

              <div
                className={classNames(
                  "flex min-w-0 flex-1 flex-wrap",
                  autorsLength > 3 ? "ml-4 mt-1" : ""
                )}
              >
                {autorsLength > 0 &&
                  authors.map((item, index) => (
                    <p
                      key={item.id}
                      className="mr-1 text-xs font-semibold text-primary"
                    >
                      {item.name}
                      {index === autorsLength - 1 ? null : ","}
                    </p>
                  ))}
              </div>
            </div>
            <div className="flex w-full justify-end lg:w-1/2">
              <button
                onClick={handleAddedTofavorite}
                type="button"
                className="ml-2 inline-flex items-center border-none text-xs font-medium leading-4 text-primary hover:text-[#ffbe10] focus:ring-0 focus:ring-offset-0 lg:px-3 lg:text-sm"
              >
                {isFav ? (
                  <StarIconSolid
                    // fill={isItemFavorite(id) ? "#ffbe10" : "#fff"}
                    className={classNames(
                      // isItemFavorite(id) ? "text-[#ffbe10" : "",
                      "-ml-0.5 mr-2 h-4 w-4 text-[#ffbe10] lg:h-6 lg:w-6"
                    )}
                    aria-hidden="true"
                  />
                ) : (
                  <StarIcon
                    className={classNames("-ml-0.5 mr-2 h-4 w-4 lg:h-6 lg:w-6")}
                    aria-hidden="true"
                  />
                )}
                <span className="hidden lg:block">Merken</span>
              </button>
              <button
                onClick={() => handleCopyArticleToClipboard(id, name)}
                type="button"
                //hover:text-[#ffbe10]
                //focus:ring-btnColor-secondary
                className="ml-2 inline-flex items-center border-none text-xs font-medium leading-4 text-primary hover:text-[#ffbe10]  focus:outline-none  focus:ring-2  focus:ring-offset-2 lg:px-3 lg:text-sm"
              >
                <ArrowUpOnSquareIcon
                  className="-ml-0.5 mr-2 h-4 w-4 lg:h-6 lg:w-6"
                  aria-hidden="true"
                />
                <span className="hidden lg:block">Teilen</span>
              </button>
            </div>
          </div>
          {/* Content area */}
          <div className="pt-5 lg:aspect-h-6">
            <h4 className="text-xl font-bold tracking-tight text-primary md:text-[28px]">
              {name}
            </h4>
            {subtitle && (
              <div className="my-2">
                <p className="text-base font-medium text-slate-600">
                  {subtitle}
                </p>
              </div>
            )}
            <div className={`${subtitle ? "mt-5" : "mt-4"}`}>
              <p className="font-sm text-sm text-slate-400">
                Veröffentlichung:
                {dateFormat({ date: publicationDate })}
              </p>
            </div>

            <div className="flex flex-col">
              {/* flex flex-col justify-center py-2 */}

              <Disclosure>
                {({ open }) => (
                  <>
                    {!open && (
                      <div className={isLongContent ? "overflow-clip" : ""}>
                        <div
                          // style={{
                          //   height: getArticleAreaHeightBasedOnArticleContent(
                          //     isLongContent,
                          //     hasSubTitle,
                          //     isLongTitle
                          //   ),
                          // }}
                          className="break-normal py-4 text-[14px] leading-[1.857] text-primary"
                          dangerouslySetInnerHTML={{
                            __html: isLongContent
                              ? //680 old app
                                description
                                  .slice(0, hasSubTitle ? 680 : 880)
                                  .concat("...")
                              : description,
                          }}
                        />
                      </div>
                    )}
                    <Transition
                      show={open}
                      enter="transition duration-1000 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="py-6">
                        <div
                          className="text-[14px] leading-[1.857] text-primary md:overflow-hidden"
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </Disclosure.Panel>
                    </Transition>
                    {isLongContent && (
                      <Disclosure.Button
                        // onClick={() => setVisibility(!hidden)}
                        className="focus-visible:ring-none flex w-full pb-1 text-[11px] font-semibold leading-[2.182]
                   text-textColor-navigation hover:text-primary focus:outline-none
                   focus-visible:ring"
                      >
                        {/* <ChevronUpIcon
                          className={`${
                            !open ? "rotate-180 transform" : ""
                          } h-7 w-7 text-secondary`}
                        /> */}
                        <span>
                          {!open ? "MEHR ANZEIGEN" : "WENIGER ANZEIGEN"}
                        </span>
                      </Disclosure.Button>
                    )}
                  </>
                )}
              </Disclosure>
              <div className="mb-2 mt-10 flex lg:mt-4">
                <a href={link} target="_blank" rel="noreferrer">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-[8px] border border-transparent bg-btnColor-secondary px-5 py-4 text-sm font-normal leading-4 text-white shadow-sm hover:bg-btnColor-hoveSecondary focus:outline-none focus:ring-2 focus:ring-btnColor-secondary focus:ring-offset-2"
                  >
                    <ArrowRightIcon
                      className="-ml-0.5 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Medium öffnen
                  </button>
                </a>
              </div>
              {restriction ? (
                <div className="mt-2 flex">
                  <img
                    className="mr-2 h-6 w-6 text-black"
                    src={LockClosedIcon}
                    alt="restricted content"
                  />
                  <p>{restriction.hint ? restriction.hint : ""}</p>
                </div>
              ) : null}
            </div>
          </div>

          {/* {isLongContent && (
            <div
              className={classNames(
                isLongContent ? "h-4" : "md:h-12",
                "mx-2 border-b border-gray-300"
              )}
            />
          )} */}
        </div>
      </div>
      {showImageGallery && (
        <ImageGallery
          ref={ref}
          //@ts-ignore
          items={pictures.map((picture) => {
            return {
              original: getImageUrl(picture.file.url),
              thumbnail: getImageUrl(picture.file.thumb.url),
              description: picture.copyright,
            };
          })}
          showFullscreenButton
          // showBullets
          showPlayButton={false}
          onImageLoad={() => {
            setImageError(false);
          }}
          onThumbnailError={() => setThunbnailError(true)}
          disableKeyDown={true}
          onImageError={() => setImageError(true)}
          onScreenChange={handleScreenChange}
          showNav
          thumbnailPosition="bottom"
          renderFullscreenButton={(onClick, isFullscreen) => (
            <Fullscreen
              onClick={() => {
                toggleImageGallery(!showImageGallery);
              }}
              isFullscreen={isFullscreen}
            />
          )}
        />
      )}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ className: "text-sm", duration: 9000 }}
      />
    </div>
  );
};

// export const Accordionlayout = () => {
//   const [open, setOpen] = useState(true);
//   console.log("open", open);
//   return (
//     <Accordion>
//       <Accordion.Panel isOpen={open}>
//         <Accordion.Title onClick={() => setOpen(!open)}>
//           What is Flowbite?
//         </Accordion.Title>
//         <Accordion.Content>
//           <p className="mb-2 text-gray-500 dark:text-gray-400">
//             Flowbite is an open-source library of interactive components built
//             on top of Tailwind CSS including buttons, dropdowns, modals,
//             navbars, and more.
//           </p>
//         </Accordion.Content>
//       </Accordion.Panel>
//     </Accordion>
//   );
// };
