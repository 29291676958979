import Collapse from "~/components/collabsable";
import type { Curriculums } from "~/types";
import { getImageUrl } from "~/utils";

import { Hyperlink } from "~/components";
import { Fragment } from "react";

export default function Curricula({
  curriculums,
}: {
  curriculums: Array<Curriculums>;
}) {
  return (
    <Collapse
      defaultOpen={true}
      name={
        <h1 className="text-xl text-[24px] font-bold leading-[1.857] text-primary">
          Lehrplanbezüge
        </h1>
      }
      content={
        <>
          {curriculums.map((curriculum) => {
            return (
              <div key={curriculum.id} className="py-4">
                {/* Avatar area */}
                <div className="flex items-center py-1">
                  <img
                    className="mr-4 h-10 w-8"
                    src={getImageUrl(curriculum.avatar.url)}
                    alt={curriculum.name}
                  />

                  <h3 className="mb-2 text-[18px] font-bold text-primary">
                    {curriculum.name}
                  </h3>
                </div>

                {/* content area */}
                <div className="my-2">
                  {curriculum.secondaryEducations.map((secondaryEducation) => {
                    return (
                      <Fragment key={secondaryEducation.id}>
                        {secondaryEducation.academicYears.map(
                          (academicYear) => {
                            return (
                              <Fragment key={academicYear.id}>
                                <div className="my-3 flex-1 space-y-1 rounded-xl bg-white p-4 shadow-curriculum">
                                  <div className="flex">
                                    <h3 className="text-sm text-slate-500">
                                      {secondaryEducation.name} |{" "}
                                      {academicYear.name}
                                    </h3>
                                  </div>
                                  {academicYear.subjectAreas.map(
                                    ({
                                      id,
                                      externalLink,
                                      name,
                                      learningUnits,
                                    }) => {
                                      return (
                                        <>
                                          <div
                                            className="flex items-center justify-between py-2"
                                            key={id}
                                          >
                                            <p className="text-[16px] text-primary">
                                              {name}.
                                            </p>
                                            <Hyperlink
                                              to={externalLink}
                                              className="hover:text-gray-500"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="h-8 w-8"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
                                                />
                                              </svg>
                                            </Hyperlink>
                                          </div>
                                          {/*  this part need to be included in the design check with Meng */}
                                          {learningUnits &&
                                            learningUnits.length > 0 && (
                                              <div>
                                                <div>
                                                  <p className="font-semibold tracking-tight text-slate-600">
                                                    Beispielhafte Lernsequenzen
                                                  </p>
                                                </div>
                                                <div>
                                                  <ul className="list-disc leading-loose tracking-tight text-slate-500">
                                                    {learningUnits.map(
                                                      (unit) => (
                                                        <li
                                                          key={unit.id}
                                                          className="ml-4"
                                                        >
                                                          {unit.name}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            )}
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </Fragment>
                            );
                          }
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      }
    />
  );
}
