import type { teachingMethod } from "~/types";
import Collapse from "~/components/collabsable";
import { Hyperlink } from "~/components";

type MethodenComponent = {
  didacticMethodicalHint?: string;
  material?: string;
  teachingMethods?: Array<teachingMethod>;
  inclusionHint?: string;
};
export default function DidacticMethodicalHint({
  didacticMethodicalHint,
  material,
  teachingMethods,
  inclusionHint,
}: MethodenComponent) {
  if (
    !didacticMethodicalHint &&
    !material &&
    !teachingMethods?.length &&
    !inclusionHint
  )
    return null;
  return (
    <Collapse
      defaultOpen={true}
      name={
        <h1 className="text-left text-xl font-bold tracking-tight text-primary md:text-[24px]">
          {didacticMethodicalHint ? "Didaktische & Methodische Hinweise" : ""}
          {inclusionHint && !didacticMethodicalHint
            ? "Hinweise für inklusives Lernen:"
            : ""}
        </h1>
      }
      content={
        <div
        // className={"border-b border-gray-200"}
        >
          {didacticMethodicalHint ? (
            <div className="mt-8">
              <div
                className="max-w-2xl overflow-hidden text-[14px] leading-[1.857] tracking-wide text-primary"
                dangerouslySetInnerHTML={{
                  __html: didacticMethodicalHint,
                }}
              />
            </div>
          ) : null}
          {material ? (
            <div className="mt-10">
              <h1 className="pb-2 text-[18px]  font-bold text-primary sm:text-lg">
                Material:
              </h1>
              <div
                className="max-w-2xl overflow-hidden text-[14px] leading-[1.857] tracking-wide text-primary"
                dangerouslySetInnerHTML={{
                  __html: material,
                }}
              />
            </div>
          ) : null}
          {inclusionHint ? (
            <div className="mt-10">
              {/* here we'r showing the headling incase didacticMethodicalHint because otherwise it will be shown as Collapse title */}
              {didacticMethodicalHint && (
                <h1 className="pb-2 text-[18px]  font-bold text-primary sm:text-lg">
                  Hinweise für inklusives Lernen:
                </h1>
              )}
              <div
                className="max-w-2xl overflow-hidden text-[14px] leading-[1.857] tracking-wide text-primary"
                dangerouslySetInnerHTML={{
                  __html: inclusionHint,
                }}
              />
            </div>
          ) : null}
          {teachingMethods?.length ? (
            <div className="mt-10 mb-16">
              <h1 className="pb-4 text-[18px]  font-bold text-primary sm:text-lg">
                Methoden:
              </h1>
              {teachingMethods.map((method) => {
                return (
                  <Hyperlink
                    to={`/method_glossar/${method.id}`}
                    key={method.id}
                  >
                    <span className="my-2 mr-2 inline-flex items-center rounded-xl bg-lightGray px-4 py-1 text-sm font-medium text-gray-800">
                      {method.name}
                    </span>
                  </Hyperlink>
                );
              })}
            </div>
          ) : null}
        </div>
      }
    />
  );
}
