import { CheckBox, CustomSelect } from "~/shared";
import type { WeiterFilterProp } from "~/screens/search_filter/types";
import { classNames } from "~/utils";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
// import { useSearchParams } from "@remix-run/react";

export const WeiterFilter = ({
  filterData,
  tagsList,
  authorList,
  setFieldValue,
  fieldsValue,
}: WeiterFilterProp) => {
  // const [searchParams] = useSearchParams();
  // const isValueInParams = (value: string) => {
  //   return searchParams.getAll(value).filter(Boolean).length > 0;
  // };
  // console.log("fieldsValue", fieldsValue);
  const authValue = fieldsValue["authorIds"] ? fieldsValue["authorIds"] : "";
  const tagsValue = fieldsValue["tagIds"] ? fieldsValue["tagIds"] : "";
  const [visible, setVisibility] = useState(false);
  return (
    <>
      {authorList && authorList.length ? (
        <div className="border-t border-gray-200 pt-6">
          <>
            <h3 className="-my-3 flow-root">
              <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                <span className="font-bold text-textColor-light">
                  Autor*innen:
                </span>
              </div>
            </h3>
            <div className="mt-4">
              <CustomSelect
                searchIcon
                //@ts-ignore
                value={authValue}
                //@ts-ignore
                onChange={setFieldValue}
                name="authorIds"
                placeholder="Suche nach Autor*innen"
                id="authorIds"
                options={authorList}
              />
            </div>
          </>
        </div>
      ) : null}
      {filterData &&
        filterData.map((section, index) => {
          let isLondSec =
            section &&
            section.options &&
            section.options.length > 8 &&
            section.name === "Themenschwerpunkte";
          return (
            <fieldset
              key={section?.id}
              className={classNames(
                "border-b border-[#EFEFEF] py-6",
                index === 0 ? "mt-10 border-t" : ""
              )}
            >
              <>
                <h3 className="-my-3 flow-root">
                  <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                    <span className="font-bold text-textColor-light">
                      {section.name}
                    </span>
                  </div>
                </h3>
                <div className="pt-6">
                  <div
                    className={classNames(
                      "space-y-4",
                      !visible && isLondSec ? "h-72 overflow-hidden" : ""
                    )}
                  >
                    {section &&
                      section.options &&
                      section.options.map((option, optionIdx) => {
                        let checkBox = fieldsValue[`${section?.id}`];

                        const currentValue =
                          checkBox && checkBox.length
                            ? checkBox.filter((item) => item?.id == option?.id)
                            : 0;

                        return (
                          <div key={option?.id} className="flex items-center">
                            <CheckBox
                              formFieldValue={{
                                name: option?.name,
                                id: option?.id,
                              }}
                              setFieldValue={setFieldValue}
                              label={option.name}
                              value={
                                currentValue && currentValue.length
                                  ? currentValue[0]?.id
                                  : 0
                              }
                              // key={option.id}
                              id={`${section?.id}`}
                              name={`${section?.id}`}
                              checked={!!(currentValue && currentValue.length)}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div className="mt-6 border-none">
                    {isLondSec && (
                      <button
                        type="button"
                        onClick={() => setVisibility(!visible)}
                        className="focus-visible:ring-none flex w-full pb-1 text-[11px] font-semibold leading-[2.182]
                   text-textColor-navigation hover:text-primary focus:outline-none
                   focus-visible:ring"
                      >
                        <ChevronUpIcon
                          className={classNames(
                            !visible ? "rotate-180 transform" : "",
                            "mr-2 h-6 w-6 text-secondary"
                          )}
                        />
                        <span>
                          {!visible ? "MEHR ANZEIGEN" : "WENIGER ANZEIGEN"}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            </fieldset>
          );
        })}
      <div className="pt-6">
        <h3 className="-my-3 flow-root">
          <div className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
            <span className="font-bold text-textColor-light">Stichwörter:</span>
          </div>
        </h3>
        <div className="mt-4 h-80">
          {tagsList && tagsList.length ? (
            <CustomSelect
              //@ts-ignore
              onChange={setFieldValue}
              //@ts-ignore
              value={tagsValue}
              //@ts-ignore
              options={tagsList}
              isMulti
              name={"tagIds"}
              placeholder="Stichwörter suchen"
              id="tagIds"
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
