import { useState } from "react";
import { Tabs } from "~/shared";
import DidacticMethodicalHint from "./didacticMethodicalHint";
import Curricula from "./curricula";
import SideBarArticle from "./tab_sidebar";
import SequencesArticle from "./sequences_article";

import type { ArticleData } from "~/types";
import type { currentTabProp } from "~/shared";

export const ArticleTabs = ({
  didacticMethodicalHint,
  material,
  teachingMethods,
  curriculums,
  mediaKindsAsString,
  link,
  createdAt,
  licenses,
  extent,
  specials,
  subjects,
  mediaCompetences,
  level,
  tagList,
  sequences,
  inclusionHint,
  inclusions,
  reversedSequences,
}: ArticleData) => {
  //   const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [current, setCurrent] = useState<currentTabProp>({
    status: "unassigned",
    id: 1,
  });
  const tabs = [
    {
      name: "Religionsunterricht",
      status: "default",
      id: 1,
    },
    // { name: "Schulpastoral", status: "inprogress", id: 0 },
    // { name: 'Aktive Vorgänge', status: 'IN_PROGRESS', id: 1, hasCounter: true },

    // { name: "Fortbildungen", status: "inprogress", id: 2 },
    // { name: "ru-digital", status: "inprogress", id: 3 },
  ];
  //   console.log("curriculums", curriculums);
  const renderCurrentTab = () => {
    switch (current.id) {
      case 0:
        return null;
      case 2:
        return null;

      // case 3:
      //   return null;

      default:
        return (
          <section aria-labelledby="products-heading" className="pt-4 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-8 lg:grid-cols-4 2xl:gap-1">
              {/* left tabs content  grid */}
              <div className="lg:col-start-1 lg:col-end-3">
                <DidacticMethodicalHint
                  didacticMethodicalHint={didacticMethodicalHint}
                  material={material}
                  teachingMethods={teachingMethods}
                  inclusionHint={inclusionHint}
                />
                {sequences.length ? (
                  <SequencesArticle sequences={sequences} />
                ) : null}
                <Curricula curriculums={curriculums} />
              </div>

              {/* Side NAv */}
              <div className="h-full lg:col-start-3 lg:col-end-5">
                <SideBarArticle
                  reversedSequences={reversedSequences}
                  inclusions={inclusions}
                  level={level}
                  createdAt={createdAt}
                  licenses={licenses}
                  mediaKindsAsString={mediaKindsAsString}
                  link={link}
                  extent={extent}
                  specials={specials}
                  subjects={subjects}
                  mediaCompetences={mediaCompetences}
                  tagList={tagList}
                />
              </div>
            </div>
          </section>
        );
    }
  };

  return (
    <div className="pt-16">
      <Tabs
        tabs={tabs}
        onSelect={setCurrent}
        current={current.id}
        counter={0}
      />
      {renderCurrentTab()}
    </div>
  );
};
