import { InklusionCard } from "~/shared";
// import graybackground from "~/assets/images/inkusion/bg-grey.jpg";
// import type { InklusionLinkCards } from "~/api/get_inkusion_data.server";
import { Section } from "~/layout/section";
// import { classNames } from "~/utils";
import waveWhite from "~/assets/images/inkusion/wave-bg-top-white.svg";
import curvbackground from "~/assets/images/start_how_itworsk_wave.svg";

export const CardInfoSection = ({ cards }: { cards: any[] }) => {
  return (
    <div
      className="relative md:pt-36"
      style={{
        backgroundColor: "#eff1f2",
        // backgroundImage: `url(${graybackground})`,
        backgroundSize: "100%",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="absolute top-0 z-40 hidden w-full md:block">
        <img alt="" className="z-50 w-full object-fill" src={curvbackground} />
      </div>
      <Section>
        <div className="grid grid-cols-1 gap-4 pb-28 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:mt-0 xl:grid-cols-5">
          {cards.map((card) => (
            <div className="mt-6 md:mr-3 xl:mt-2" key={card.id}>
              <InklusionCard {...card} externalLinks />
            </div>
          ))}
        </div>
      </Section>
      <div className="absolute bottom-0 z-40 hidden w-full md:block 3xl:relative 3xl:z-0">
        <img
          alt="wave-white"
          className="z-40 w-full object-contain"
          src={waveWhite}
        />
      </div>
    </div>
  );
};
