import { PhotoIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { classNames, getImageUrl } from "~/utils";
import type { Picture } from "~/types";
import fallbackImage from "~/assets/images/about-page-third-image.png";
const renderImageBtnsDetails = (
  numberImages?: number,
  numberVideos?: number,
  handleImageClick?: () => void,
  videoUrl?: string
) => (
  <div
    className={classNames(
      "grid grid-cols-2",
      typeof numberImages === "number" && numberImages > 1
        ? "md:w-64"
        : "md:w-60"
    )}
  >
    {numberImages ? (
      <div
        className="my-3 cursor-pointer"
        onClick={handleImageClick ? () => handleImageClick() : undefined}
      >
        <span className="inline-flex items-center rounded-lg bg-lightBackground px-3 py-2 text-xs text-black hover:bg-btnColor-hoverNav lg:px-4 lg:text-sm lg:font-medium">
          <PhotoIcon className="mr-1 h-3 w-3 lg:mr-3 lg:h-6 lg:w-6" />
          {`${numberImages} ${numberImages > 1 ? "Bilder" : "Bild"}`}
        </span>
      </div>
    ) : null}
    {numberVideos ? (
      <div className="my-3 flex justify-end md:block">
        <a href={videoUrl} target="_blank" rel="noreferrer">
          <span className="inline-flex items-center rounded-lg bg-lightBackground px-3 py-2 text-xs text-black hover:bg-btnColor-hoverNav lg:px-4 lg:text-sm lg:font-medium">
            <VideoCameraIcon className="mr-1 h-3 w-3 lg:mr-3 lg:h-6 lg:w-6" />
            {`${numberVideos} ${numberVideos > 1 ? "Videos" : "Video"}`}
          </span>
        </a>
      </div>
    ) : null}
  </div>
);

const ArticleImage = ({
  pictures,
  videoUrl,

  toggleImageGallery,
}: {
  pictures: Array<Picture>;
  videoUrl?: string;

  toggleImageGallery: () => void;
}) => {
  const numberOfVideos = videoUrl ? 1 : undefined;
  const numberOfImages = pictures ? pictures.length : undefined;
  const articleImageUrl =
    pictures && pictures.length ? pictures[0].file.url : undefined;

  return (
    <div className="relative px-3 lg:px-0">
      {/* Testimonial card*/}
      {/* rounded-2xl */}
      <div className="aspect-w-8 aspect-h-6 relative block w-full">
        <img
          className="rounded-xl object-cover"
          src={articleImageUrl ? getImageUrl(articleImageUrl) : fallbackImage}
          alt=""
        />
        {/* <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
              <div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" /> */}
      </div>
      <div className="absolute bottom-0 left-0 right-0 px-5 lg:px-3">
        <footer className="my-1 flex w-full justify-between">
          {renderImageBtnsDetails(
            numberOfImages,
            numberOfVideos,
            toggleImageGallery,
            videoUrl
          )}

          {/* <div className="flex space-x-6">
            <span className="inline-flex items-center text-sm">
              <a href={link} target="_blank" rel="noreferrer">
                <button className="focus:ring-gray-10- flex-col rounded-lg py-3 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 md:flex-row md:bg-lightGray md:px-5">
                  <span className="pt-2 font-medium text-gray-900 md:pt-0">
                    Medium öffnen
                  </span>
                </button>
              </a>
            </span>
          </div> */}
        </footer>
      </div>
    </div>
  );
};

export default ArticleImage;
