import { Link } from "@remix-run/react";
import Collapse from "~/components/collabsable";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
// import { Card } from "~/shared";
import type { ArticleData } from "~/types";
import { getImageUrl, dateFormat } from "~/utils";
type keys = "sequences";

type SequenceProp = Pick<ArticleData, keys>;

export default function SequencesArticle({ sequences }: SequenceProp) {
  // console.log("sequences", sequences);
  // const articleLinkWithName = (id: number, name: string) =>
  //   `/media/articles/${id}/${name.replace(/\s/g, "-")}`;

  return (
    <Collapse
      defaultOpen={true}
      name={
        <h1 className="text-[24px] text-xl font-bold leading-[1.857] text-primary">
          Eingebettete Medien
        </h1>
      }
      content={
        <>
          <div>
            <h3 className="mb-0 text-[16px] leading-normal">
              Medien, die Teil des aufgelisteten Materials sind und in weiteren
              Kontexten Verwendung finden können.
            </h3>
          </div>
          {sequences.map((sequence) => {
            return (
              <div key={sequence.id} className="p-2">
                {/* Avatar area */}
                {/* content area */}
                <div className="my-1">
                  {sequence.linkedMedium && (
                    <div className="relative space-y-2 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                        <img
                          className="rounded-lg object-cover shadow-lg"
                          src={
                            sequence.linkedMedium.firstPicture
                              ? getImageUrl(
                                  sequence.linkedMedium.firstPicture.file.url
                                )
                              : "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                          }
                          alt=""
                        />
                      </div>
                      <div className=" sm:col-span-2">
                        <div className="space-y-1">
                          <div className="space-y-1">
                            <h3 className="text-lg font-bold">
                              {sequence.linkedMedium.authorsAsString}
                            </h3>
                            <p className="text-slate-500">
                              {dateFormat({
                                date: sequence.linkedMedium.createdAt,
                              })}
                            </p>
                          </div>
                          {sequence.linkedMedium.extent && (
                            <div className="text-lg">
                              <p className="text-gray-500">
                                Umfang: {sequence.linkedMedium.extent.name}
                              </p>
                            </div>
                          )}
                          {sequence.linkedMedium.secondaryEducationAsString && (
                            <div className="text-lg">
                              <p className="text-gray-500">
                                Schulbereich:{" "}
                                {
                                  sequence.linkedMedium
                                    .secondaryEducationAsString
                                }
                              </p>
                            </div>
                          )}
                          <div className="mt-4 md:absolute md:bottom-4 md:mt-0">
                            <Link
                              to={`/search/main/${sequence.linkedMedium.id}/${sequence.linkedMedium.author}`}
                              className="inline-flex items-center rounded-none
                                                          border-b border-primary pl-1 pb-1 text-sm font-medium text-textColor-primary"
                              // rel="noreferrer"
                            >
                              Mehr erfahren
                              <ArrowRightIcon
                                className="ml-3 h-5 w-6 text-textColor-dark"
                                aria-hidden="true"
                              />
                            </Link>
                          </div>
                          {/* <ul className="flex space-x-5">
                            <li>
                              <a
                                href={"person.twitterUrl"}
                                className="text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">Twitter</span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                              </a>
                            </li>
                            <li>
                              <a
                                href={"person.linkedinUrl"}
                                className="text-gray-400 hover:text-gray-500"
                              >
                                <span className="sr-only">LinkedIn</span>
                                <svg
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul> */}
                        </div>
                      </div>
                    </div>
                    //   <Card
                    //     attributes={{
                    //       name: sequence.linkedMedium.name,
                    //       firstPictureUrl:
                    //         "sequence.linkedMedium.pictures[0].file.url",
                    //       description: sequence.linkedMedium.description,
                    //       extentString: "Sequenz",
                    //       mediaKindsString:
                    //         sequence.linkedMedium.mediaKindsAsString,
                    //     }}
                    //     //@ts-ignore
                    //     ref={null}
                    //     itemInFavorite={false}
                    //     // showPendingSpinner={showPendingSpinner}
                    //     action={() => null}
                    //     key={sequence.linkedMedium.id}
                    //     handleClickCard={() => null}
                    //     cardLinkAction={() =>
                    //       articleLinkWithName(
                    //         sequence.linkedMedium.id,
                    //         sequence.linkedMedium.name
                    //       )
                    //     }
                    //   />
                  )}
                </div>
              </div>
            );
          })}
        </>
      }
    />
  );
}
