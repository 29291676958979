import type { IPartner } from "~/types";
// import { getImageUrl } from "~/utils";
import { Section } from "~/layout/section";
export const PartnersLogos = ({
  cards,
  title,
  baseUrl,
}: {
  cards: Array<IPartner>;
  title: string;
  baseUrl?: string;
}) => {
  return (
    <Section>
      <h1 className="text-center text-lg font-bold uppercase leading-[4.444] text-primary">
        {title}
      </h1>
      <div className="grid grid-cols-4 items-center gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0  lg:grid-cols-5">
        {cards.map((card) => (
          <img
            key={card.id}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={baseUrl + card.logo.small.url}
            alt="partner"
            width={200}
            height={80}
          />
        ))}
      </div>
    </Section>
  );
};
