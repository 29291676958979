import { Dialog, Transition } from "@headlessui/react";
import { Fragment, type ReactChild, type ReactElement } from "react";
// import { Fragment } from "react";
// import { Dialog, Transition } from "@headlessui/react";
// import { XMarkIcon } from "@heroicons/react/24/outline";
// import { classNames } from "../../utils";
import ReactModal from "react-modal";
import { classNames } from "~/utils";

// import styles from "./styles.css";

// styles is now something like /build/global-AE33KB2.css

// export function links() {
//   return [{ rel: "stylesheet", href: styles }];
// }

type SlideProp = {
  title?: string | ReactElement;
  children: ReactChild;
  toggle: (open: boolean) => void;
  open: boolean;
  footerContent?: ReactChild;
  subTitle?: string;
  dir?: "left" | "right";
  size: "small" | "xlarge" | "large" | "medium" | "xxl";
  style?: {
    titleStyle?: String;
    subtitleStyle?: string;
    closeIconColor?: string;
    headerStyle?: string;
    bodyStyle?: string;
  };
  width?: string;
  parentSelector?: string;
};

const SlideOver: React.FunctionComponent<SlideProp> = ({
  // title,
  dir = "left",
  children,
  open,
  size,
  width = "max-w-md",
  parentSelector = "#main_filter",
  toggle
  // footerContent,
  // subTitle,
  // style = {
  //   titleStyle: "text-gray-800",
  //   subtitleStyle: "text-sm text-white",
  //   closeIconColor: "text-gray-800",
  //   headerStyle: "bg-white",
  //   bodyStyle: "bg-white",
  // },
}) => {
  const leftSideSlider = dir === "left";
  // console.log("🚀 ~ file: index.tsx:58 ~ leftSideSlider:", leftSideSlider);

  return (
    <Transition.Root show={open} as={"div"}>
      <ReactModal
        closeTimeoutMS={800}
        ariaHideApp={false}
        role="div"
        keepMounted
        // className={`modal-animated`}
        // overlayClassName={`modal-slider--overlay`}
        isOpen={open}
        //@ts-ignore
        parentSelector={() => document.querySelector(parentSelector)}
        htmlOpenClassName="ReactModal__Html--open"
        // as="div"
        // className="relative z-50"
        // onClose={toggle}
        // unmount={false}

        style={{
          content: {
            position: "absolute",
            top: 60,
            left: 0,
            right: 0,
            bottom: 0,
            border: "0px solid transparent",
            overflow: "auto",
            backgroundColor: "rgb(0 0 0 / 80%)",
            opacity: size === "small" ? 1 : 0.8,
            WebkitOverflowScrolling: "touch",
            borderRadius: "0",
            outline: "none",
            padding: "0px",
            backdropFilter: "blur(3px)"
          }
        }}>

        {/* <div className="fixed inset-0" /> */}

        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0  overflow-hidden">
            <div
              className={classNames(
                "pointer-events-none fixed inset-y-0 flex max-w-full",
                leftSideSlider ?
                "  md:pr-10 lg:-left-[450px]" :
                "right-0  pr-0 md:pl-10"
              )}>

              <Transition.Child
                as={"div"}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={
                leftSideSlider ? "translate-x-0" : "lg:translate-x-full"}

                enterTo={
                leftSideSlider ? "lg:translate-x-full" : "translate-x-0"}

                leave={
                "transform transition ease-in-out duration-500 sm:duration-700"}

                leaveFrom={
                leftSideSlider ? "lg:translate-x-full" : "translate-x-0"}

                leaveTo={
                leftSideSlider ? "translate-x-0" : "lg:translate-x-full"}>


                <div
                  className={`pointer-events-auto w-screen ${width} h-[100%]`}>

                  {children}
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </ReactModal>
    </Transition.Root>);

};

export default SlideOver;