import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import type { InkusionSectionProp } from "~/api/get_inkusion_data.server";
import { Section } from "~/layout/section";
import { Modal } from "~/shared";
import heroImage from "~/assets/images/inkusion/hero-inklusion.jpg";

import { getImageUrl } from "~/utils";

export const InklusionInfoSection = ({
  subtitle,
  body,
  picture,
  slug,
  popoverCards,
}: InkusionSectionProp) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  // console.log("picture", picture);
  return (
    <Section>
      <div className="relative" id={slug}>
        {/* lg:grid lg:grid-cols-2 lg:gap-8 */}
        <div className="w-full  md:mx-auto">
          <h2 className="text-2xl leading-tight text-primary lg:text-6xl">
            {subtitle}
          </h2>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          {/* @ts-ignore */}
          {picture && picture.length !== 0 && (
            <div className="relative lg:col-start-2 lg:row-start-1">
              <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      className="rounded-3xl object-cover object-center shadow-lg"
                      src={getImageUrl(picture.file.slider?.url) || heroImage}
                      alt={picture.name || "heroImage inkusion"}
                      width={614}
                      height={450}
                    />
                  </div>
                  {picture.copyright && (
                    <figcaption className="mt-3 flex justify-center text-sm text-textColor-primary md:justify-end">
                      <AtSymbolIcon
                        className="h-5 w-5 flex-none"
                        aria-hidden="true"
                      />
                      <span className="ml-2">{picture.copyright}</span>
                    </figcaption>
                  )}
                </figure>
              </div>
            </div>
          )}
          <div className="mt-8 lg:mt-0">
            <div
              className="mx-auto 
              text-sm font-normal leading-6
              text-textColor-primary lg:col-start-1 lg:row-start-1 lg:max-w-none"
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </div>
        </div>
      </div>
      <dl className="relative mx-auto mt-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 md:mt-14 lg:grid-cols-3 lg:gap-x-8">
        {popoverCards.map((card) => (
          <div
            onClick={() => {
              setContent(card.popoverContent);
              setOpen(!open);
            }}
            key={card.id}
            className="mt-4 flex cursor-pointer flex-row-reverse rounded-xl bg-white px-5 py-4 shadow-inklusionCard hover:bg-slate-100 md:mt-0"
          >
            <div>
              <dt className="border-l-4 border-secondary pl-2">
                <h5 className="text-[16px] font-bold uppercase text-textColor-light">
                  {card.name}
                </h5>
              </dt>

              <p className="mt-4 text-sm text-[#949494]">
                {card.shortDescription}
                <span className="ml-2 border-b-2 border-btnColor-secondary font-bold text-btnColor-secondary">
                  Mehr erfahren
                </span>
              </p>
            </div>
          </div>
        ))}
      </dl>
      <Modal open={open} planDialgo setOpen={setOpen}>
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div className="fixed inset-0 z-10 w-screen">
            <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                <div className="absolute right-0 top-0  block pr-4 pt-4">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="h-[calc(100vh-120px)] overflow-y-auto sm:flex sm:h-[600px] sm:items-start">
                  <div className="mt-3 text-left">
                    <div className="mt-2">
                      <div
                        className="modal-inklusion flex flex-col text-sm font-normal  leading-6 text-textColor-primary"
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Section>
  );
};
