import { Link, useFetcher } from "@remix-run/react";

export function CookiesBanner() {
  const analyticsFetcher = useFetcher();

  return (
    <analyticsFetcher.Form method="post" action="/cookies_consent">
      <div className="group pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
        <div className="pointer-events-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
          <p className="text-sm leading-6 text-gray-900">
            Wir nutzen Cookies auf unserer Website. Einige von ihnen sind
            essenziell, während andere uns helfen, diese Website und Ihre
            Erfahrung zu verbessern{" "}
            <Link
              to="/datenschutz"
              className="mb-2 border-b border-b-blue-800  font-semibold text-gray-900"
            >
              Datenschutz
            </Link>
            .
          </p>
          <div className="mt-4 flex items-center gap-x-5">
            <button
              name="accept-gdpr-all"
              value="true"
              type="submit"
              className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 group-hover:bg-orange-500"
            >
              Alle Akzeptieren
            </button>
            <button
              name="accept-gdpr-necessary"
              value="true"
              type="submit"
              className="rounded-md border px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-700 hover:text-white"
            >
              Notwendig
            </button>
            {/* <button
              name="reject-gdpr"
              value="true"
              type="submit"
              className="rounded-md border px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-700 hover:text-white"
            >
              Reject all
            </button> */}
          </div>
        </div>
      </div>
    </analyticsFetcher.Form>
  );
}
