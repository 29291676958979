// import { useContext } from "react";
// import { CookieConsentContext } from "~/components/CookieConsent";

interface GoogleAnalyticsProps {
  googleAnalyticsId?: string;
  analytic: boolean;
  nonce?: string;
  isProduction?: boolean;
}

export const GoogleAnalytics = ({
  analytic,
  nonce,
  googleAnalyticsId,
  isProduction,
}: GoogleAnalyticsProps) => {
  //   const { analytics } = useContext(CookieConsentContext);

  return (
    <>
      {analytic && isProduction && googleAnalyticsId ? (
        <>
          <script
            id={"gtm"}
            async
            suppressHydrationWarning
            nonce={nonce}
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
          />

          <script
            id={"google-analytics"}
            suppressHydrationWarning
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${googleAnalyticsId}');`,
            }}
          />
        </>
      ) : null}
    </>
  );
};
