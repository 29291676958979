import Collapse from "~/components/collabsable";
import { Hyperlink } from "~/components";
import type {
  Item,
  ArticleData,
  // IReversedSequence
} from "~/types";
import { classNames, dateFormat } from "~/utils";
// import { ListItem } from "~/shared";
// type BlogKeys = keyof ArticleData;
type keys =
  | "createdAt"
  | "licenses"
  | "mediaKindsAsString"
  | "link"
  | "extent"
  | "specials"
  | "mediaCompetences"
  | "level"
  | "tagList"
  | "subjects"
  | "inclusions"
  | "reversedSequences";
type SideBarProp = Pick<ArticleData, keys>;

const renderTabValue = ({ data }: { data?: Array<Item> }) => (
  <h3 className="mt-1 text-sm text-black">
    {data && data.length
      ? data.map((item, index) => (
          <span className="mr-2" key={item.id}>
            {item.name} {`${index !== data.length - 1 ? "," : ""}`}
          </span>
        ))
      : "_"}
  </h3>
);
// const renderReversedSequences = ({
//   data,
// }: {
//   data?: Array<IReversedSequence>;
// }) => (
//   <h3 className="mt-1 text-sm">
//     {data && data.length
//       ? data.map((item, index) => (
//           <ListItem
//             key={item.id}
//             to={`/search/main/${item.id}/${item.linkingArticle.name}`}
//           >
//             <span className="pt-1 text-orange-400">{item.description}</span>
//           </ListItem>
//         ))
//       : "_"}
//   </h3>
// );
export default function SideBarArticle({
  createdAt,
  licenses,
  mediaKindsAsString,
  link,
  extent,
  specials,
  subjects,
  mediaCompetences,
  level,
  tagList = [],
  inclusions,
  reversedSequences,
}: SideBarProp) {
  // console.log("reversedSequences", reversedSequences);
  const filters = [
    {
      id: "eigenschaften",
      name: "Eigenschaften",
      isOpen: true,
      options: [
        {
          value: (
            <h3 className="mt-1 text-sm text-slate-800">
              {mediaKindsAsString}
            </h3>
          ),
          label: "Medientyp",
        },
        {
          value: renderTabValue({ data: licenses }),
          label: "Lizenz",
        },
        {
          value: (
            <Hyperlink to={link}>
              <h3 className="mt-1 text-sm text-slate-800">Link öffnen </h3>
            </Hyperlink>
          ),
          label: "URL",
        },
        {
          value: (
            <h3 className="mt-1 text-sm text-slate-800">
              {dateFormat({
                date: createdAt,
                // option: { hour: true, minute: true },
              })}
            </h3>
          ),
          label: "Hinzugefügt am",
        },
      ],
    },
    {
      id: "pädagogische",
      name: "Pädagogische Merkmale",
      isOpen: true,
      options: [
        {
          value: renderTabValue({ data: specials }),
          label: "Einsatzbereich",
        },
        {
          value: (
            <h3 className="mt-1 text-sm text-slate-800">{extent?.name}</h3>
          ),
          label: "Umfang",
        },
        {
          value: renderTabValue({ data: subjects }),

          label: "Gegenstandsbereich",
        },
        {
          value: renderTabValue({ data: mediaCompetences }),
          label: "Medienkompetenzen",
        },
        {
          value: (
            <h3 className="mt-1 text-sm text-slate-800">
              {level ? level.name : "_"}
            </h3>
          ),
          label: "Niveau",
        },
        {
          value: renderTabValue({ data: inclusions }),
          label: "inklusives Lernen",
        },
      ],
    },
    // {
    //   id: "unterrichtskontexte",
    //   name: "Unterrichtskontexte",
    //   options: [
    //     {
    //       value: renderReversedSequences({ data: reversedSequences }),
    //       // label: "Unterrichtskontexte",
    //     },
    //   ],
    // },
    {
      id: "stichwörter",
      name: "Stichwörter",
      options: [
        {
          value: tagList.length ? (
            <div className="flex flex-wrap">
              {tagList.map((tag) => (
                <span
                  key={tag}
                  className={classNames(
                    "mr-2 inline-flex items-center rounded-xl bg-lightGray px-4 py-1 text-sm font-medium text-gray-800",
                    tagList.length > 2 ? "mt-2" : ""
                  )}
                >
                  {tag}
                </span>
              ))}
            </div>
          ) : (
            <h3 className="mt-1 text-sm text-slate-800">Keine Stichwörter</h3>
          ),
          label: "",
        },
      ],
    },
  ];

  return (
    <div className="flex w-full flex-col items-end">
      <div className="w-full max-w-lg">
        {filters.map((section) => (
          <Collapse
            defaultOpen={section.isOpen}
            key={section.id}
            name={
              <span className="text-sm font-bold text-slate-800">
                {section.name}
              </span>
            }
            content={
              <>
                {section.options.map((option, optionIdx) => (
                  <div key={option.label} className="flex flex-col ">
                    <label
                      htmlFor={`filter-${section.id}-${optionIdx}`}
                      className="text-sm text-slate-400"
                    >
                      {option.label}
                    </label>
                    {option.value}
                  </div>
                ))}
              </>
            }
          />
        ))}
      </div>
    </div>
  );
}
