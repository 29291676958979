import type { IConfig } from "~/config.server";

export const ExposeAppConfig = ({
  appConfig,
  nonce,
}: {
  appConfig: IConfig;
  nonce: string;
}) => {
  return (
    <script
      id={"app-config"}
      suppressHydrationWarning
      nonce={nonce}
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(appConfig)}`, //typed in the ../types/global.d.ts
      }}
    />
  );
};
