import { useNavigate } from "@remix-run/react";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { Popover } from "@headlessui/react";
import MediaIcon from "~/assets/icons/folder-media.png";
import Meeting from "~/assets/icons/team-meeting.png";
import Book from "~/assets/icons/open-book.png";
import inklusionImage from "~/assets/icons/icon-image-inklusion.png";
import methodGlossarImg from "~/assets/icons/icon-image-methodenglossar.png";
import heroImage from "~/assets/images/inkusion/menu-image.png";
import LearnPlatformLogo from "~/assets/images/logomain.png";
import themenschwerpunkteImage from "~/assets/images/icon-menu-themenschwerpunkte.png";
import type { LinksData } from "~/api/get_static_data.server";

// const user = {
//   name: "Debbie Lewis",
//   handle: "deblewis",
//   email: "debbielewis@example.com",
//   imageUrl:
//     "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80",
// };
// const userNavigation = [
//   { name: "Profile", href: "#" },
//   { name: "Settings", href: "#" },
//   { name: "Sign out", href: "#" },
// ];
const mediaLinks = [
  {
    name: "Medien",
    description:
      "Entdecke eine Fülle an Unterrichtsmedien - von inspirierenden Impulsen bis hin zu umfangreichen Unterrichtsreihen.",
    href: "/search/main",
    src: MediaIcon,
    disabled: false,
  },
  {
    name: "Fortbildungen",
    description:
      "Du suchst eine Fortbildung? Hier erhältst du eine aktuelle Übersicht vieler Angebote vor Ort und digital.",
    href: "#",
    src: Meeting,
    disabled: true,
  },
  {
    name: "Fachartikel",
    description:
      "Mehr wissen: Finde Antworten auf Fragen zu vielfältigen Themen und erweitere deine Fachkenntnisse.",
    href: "#",
    src: Book,
    disabled: true,
  },
];
const themen = [
  // {
  //   name: "Schulpastoral",
  //   href: "#",
  //   src: user.imageUrl,
  //   description:
  //     "Get a better understanding of where your traffic is coming from.",
  // },
  {
    name: "Inklusion",
    href: "/inklusion",
    src: inklusionImage,
    description:
      "Öffne Türen für die Vielfalt:  Entdecke neue Perspektiven, Methoden und Materialien.",
  },
  {
    name: "Themenschwerpunkte",
    href: "/themenschwerpunkte",
    src: themenschwerpunkteImage,
    description: "Für Dich zusammengestellt: handverlesene Medienportfolios.",
  },
  {
    name: "Methodenglossar",
    href: "/method_glossar",
    src: methodGlossarImg,
    description:
      "Lust auf Neues: Entdecke eine Vielfalt an elementaren Methoden.",
  },
];

export const NavigationFlyoutMenu = ({
  links,
}: {
  links: Array<LinksData>;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="relative  mx-auto flex  md:max-w-[calc(100%_-_120px)]
        md:justify-start md:px-0   xl:max-w-[calc(100%_-_320px)] 3xl:max-w-8xl"
    >
      <div className="w-full pb-3 pt-5 lg:w-4/6">
        <div className="relative grid gap-2 sm:gap-8 lg:grid-cols-3">
          {mediaLinks.map((item) => (
            <Popover.Panel key={item.name}>
              {({ close }) => (
                <button
                  onClick={() => {
                    navigate(item.href);
                    close();
                  }}
                  key={item.name}
                  disabled={item.disabled}
                  className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-100 disabled:opacity-50"
                >
                  <div className="flex flex-shrink-0 items-center justify-center rounded-full  text-white sm:h-12 sm:w-12">
                    <img className="h-[46px] w-[46px]" src={item.src} alt="" />
                  </div>
                  <div className="ml-4 text-left">
                    <p className="text-sm font-bold text-primary lg:text-base">
                      {item.name}
                    </p>
                    <p className="mt-1 text-xs text-textColor-navigation 2xl:w-52">
                      {item.description}
                    </p>
                  </div>
                </button>
              )}
            </Popover.Panel>
          ))}
        </div>
        <div className="my-6">
          <span className="text-sm tracking-widest text-textColor-navigation">
            THEMEN
          </span>
          <div className="mt-1 h-0.5 bg-stone-200" />
        </div>
        <div className="relative grid gap-2 sm:gap-8 lg:grid-cols-2 2xl:grid-cols-3">
          {themen.map((item) => (
            <Popover.Panel key={item.name}>
              {({ close }) => (
                <button
                  onClick={() => {
                    navigate(item.href);
                    close();
                  }}
                  key={item.name}
                  className="-m-3 flex items-start rounded-lg px-3 py-2 hover:bg-gray-100"
                >
                  <div className="flex h-[50px] w-[61px] flex-shrink-0">
                    <img
                      className="h-[51px] w-full rounded-md object-cover"
                      src={item.src}
                      alt=""
                    />
                  </div>
                  <div className="ml-4 text-left">
                    <p className="text-sm font-bold text-primary">
                      {item.name}
                    </p>
                    <p className="mt-1 w-40 text-xs text-textColor-navigation">
                      {item.description}
                    </p>
                  </div>
                </button>
              )}
            </Popover.Panel>
          ))}
        </div>
        <div className="my-6 h-0.5 bg-stone-200" />
        <div className="relative grid gap-1 sm:gap-2 md:grid-cols-5 lg:grid-cols-4">
          {links.length &&
            links.map((item) => (
              <Popover.Panel key={item.name}>
                {({ close }) => {
                  if (item.isInternal) {
                    return (
                      <button
                        onClick={() => {
                          navigate(item.href);
                          close();
                        }}
                        key={item.name}
                        className="flex items-start rounded-lg py-1 hover:bg-gray-50"
                      >
                        <div>
                          <p className="text-sm font-bold text-primary">
                            {item.name}
                          </p>
                        </div>
                      </button>
                    );
                  }
                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-start rounded-lg py-1 hover:bg-gray-50"
                    >
                      <div>
                        <p className="text-sm font-bold text-primary">
                          {item.name}
                        </p>
                      </div>
                    </a>
                  );
                }}
              </Popover.Panel>
            ))}
        </div>
      </div>
      <div className="relative hidden px-4 lg:block lg:w-2/6">
        <div className="absolute top-5 right-0 max-w-sm pl-4">
          <img
            alt="team-hero"
            className={"z-0 w-full object-contain lg:h-[365px] xl:h-full"}
            src={heroImage}
          />
        </div>
      </div>
    </div>
  );
};

export const NavigationFlyoutMobileMenu = ({
  links,
  close,
}: {
  links: Array<LinksData>;
  close: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <div className="divide-y-2 divide-gray-50 overflow-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="px-5 pt-5 pb-6 sm:pb-8">
        <div className="flex items-center justify-between">
          <div>
            <img
              className="h-8 w-auto"
              src={LearnPlatformLogo}
              alt="LearnPlatformLogo"
            />
          </div>
          <div className="-mr-2">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-inset focus:ring-orange-500">
              <span className="sr-only">Close menu</span>
              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
        <div className="mt-4">
          <nav>
            <div className="grid gap-2 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-2">
              {mediaLinks.map((item) => (
                <Popover.Panel key={item.name}>
                  {({ close }) => (
                    <button
                      onClick={() => {
                        navigate(item.href);
                        close();
                      }}
                      key={item.name}
                      disabled={item.disabled}
                      className="-m-3 flex  items-center rounded-lg p-3 disabled:opacity-50"
                    >
                      <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md text-white">
                        <img
                          className="h-10 w-10 rounded-md object-cover"
                          src={item.src}
                          alt=""
                        />
                      </div>
                      <div className="ml-1 text-left ">
                        <p className="ml-2 text-base font-medium text-primary">
                          {item.name}
                        </p>
                      </div>
                    </button>
                  )}
                </Popover.Panel>
                // <a
                //   key={item.name}
                //   href={item.href}
                //   className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                // >
                //   <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md text-white sm:h-12 sm:w-12">
                //     <img
                //       className="h-10 w-10 rounded-md object-cover"
                //       src={item.src}
                //       alt=""
                //     />
                //   </div>
                //   <div className="ml-4 text-base font-medium text-primary">
                //     {item.name}
                //   </div>
                // </a>
              ))}
            </div>

            <div className="mt-4 grid gap-2 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-2">
              {themen.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                >
                  <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-secondary text-white">
                    <img
                      className="h-10 w-10  rounded-md object-cover"
                      src={item.src}
                      alt=""
                    />
                  </div>
                  <div className="ml-1 text-left">
                    <p className="ml-2 text-base font-medium text-primary">
                      {item.name}
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </nav>
        </div>
      </div>
      <div className="py-6 px-5">
        <div className="grid grid-cols-1 gap-4 pb-3">
          {links.length &&
            links.map((item) => {
              if (item.isInternal) {
                return (
                  <button
                    onClick={() => {
                      navigate(item.href);
                      close();
                    }}
                    key={item.name}
                    className="flex items-start rounded-lg py-2 hover:bg-gray-50"
                  >
                    <div>
                      <p className="text-sm font-bold text-primary">
                        {item.name}
                      </p>
                    </div>
                  </button>
                );
              }
              return (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-start rounded-lg py-2 hover:bg-gray-50"
                >
                  <div>
                    <p className="text-sm font-bold text-primary">
                      {item.name}
                    </p>
                  </div>
                </a>
              );
            })}
        </div>
        {/* <div className="border-t border-btnColor-navBar pt-4 pb-3">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src={user.imageUrl}
                alt=""
              />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-primary">
                {user.name}
              </div>
              <div className="text-sm font-medium text-primary">
                {user.email}
              </div>
            </div>
            <button
              type="button"
              className="ml-auto flex-shrink-0 rounded-full p-1 text-primary hover:bg-sky-800 hover:text-white focus:bg-sky-900 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-sky-900"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-3 px-2">
            {userNavigation.map((item) => (
              <button
                key={item.name}
                // as="a"
                //@ts-ignore
                href={item.href}
                className="block rounded-md py-2 text-base font-medium text-primary hover:bg-sky-800 hover:text-white"
              >
                {item.name}
              </button>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};
