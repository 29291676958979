import type { RecommendationSectionProp } from "~/api/get_inkusion_data.server";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import theme from "~/theme/default";
import { classNames } from "~/utils";
import { Section } from "~/layout/section";
import heroImage from "~/assets/images/inkusion/medienempfehlung.png";
import dotsBackground from "~/assets/images/inkusion/bg-dot-pattern-lightgray.png";

export const RecommendationSection = ({
  subsections,
  body,
  title,
  slug,
}: RecommendationSectionProp) => {
  const { button } = theme;
  const { base, color } = button;
  // console.log("subsections", subsections);
  return (
    <div
      className="relative  3xl:mx-auto
      3xl:max-w-8xl"
      style={{
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: "100%",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={`w-full 
      
      py-3
       px-3
      text-center 
      md:ml-auto 
      md:max-w-[calc(100%_-_60px)]
      md:px-0 
      md:pt-28 
      md:pb-10
      md:text-left 
      xl:max-w-[calc(100%_-_160px)] 
      3xl:mx-auto
      3xl:max-w-8xl
      `}
      >
        <div className="grid gap-16 lg:grid-cols-2">
          <div className="mt-8 flex flex-col">
            {/* className="sm:text-3xl sm:leading-none md:text-4xl" */}
            <h3 className="text-3xl font-bold leading-none md:text-4xl">
              {title}
            </h3>

            <div className="mt-10 flex flex-col md:mt-14 md:max-w-2xl">
              {[
                subsections.availableMedia,
                subsections.currentRecommendations,
              ].map((item, index) => (
                <div
                  // className={index === 0 ? "mb-10 md:mb-20" : "mb-0"}
                  key={item.id}
                >
                  <h6 className="text-2xl font-bold leading-7 text-primary">
                    {item.title}
                  </h6>
                  <div
                    className="pb-3 pt-2 text-base text-primary"
                    dangerouslySetInnerHTML={{
                      __html: item.body,
                    }}
                  />
                  <a
                    href={item.buttonLink}
                    type="button"
                    target="_blank"
                    className={classNames(base, color.secondary, "px-9")}
                    rel="noreferrer"
                  >
                    {index === 0 && (
                      <ArrowDownTrayIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                    )}
                    {item.buttonTitle}
                  </a>
                  {index === 0 && <div className="my-10 h-0.5 bg-stone-200" />}
                </div>
              ))}
            </div>
          </div>
          <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
            <img className="object-contain" src={heroImage} alt="" />
          </div>
        </div>
      </div>
      <Section>
        <div className="h-0.5 bg-stone-200" />
      </Section>
    </div>
  );
};
