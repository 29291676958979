import { InklusionCard } from "~/shared";
// import graybackground from "~/assets/images/inkusion/bg-grey.jpg";
import type { InklusionLinkCards } from "~/api/get_inkusion_data.server";
import { Section } from "~/layout/section";
// import { classNames } from "~/utils";
import waveWhite from "~/assets/images/inkusion/wave-bg-top-white.svg";

export const InKlusionCardList = ({
  cards,
}: {
  cards: InklusionLinkCards[];
}) => {
  return (
    <div
      className="relative"
      style={{
        backgroundColor: "#eff1f2",
        // backgroundImage: `url(${graybackground})`,
        backgroundSize: "100%",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "repeat",
      }}
    >
      <Section>
        <div className="flex w-full flex-col pb-28 md:flex-row">
          {cards.map((card) => (
            <div className="mt-10 md:mr-3 md:mt-0" key={card.id}>
              {/* @ts-ignore */}
              <InklusionCard {...card} />
            </div>
          ))}
        </div>
      </Section>
      <div className="absolute bottom-0 z-40 w-full 3xl:relative 3xl:z-0">
        <img
          alt="wave-white"
          className="z-40 w-full object-contain"
          src={waveWhite}
        />
      </div>
    </div>
  );
};
