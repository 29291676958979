import { useNavigate, useParams } from "@remix-run/react";
import { classNames } from "~/utils";

export const Breadcrumb = ({
  mainPage = "Alle Medien",
  detailsPage = "Artikel",
}: {
  mainPage?: string;
  detailsPage?: string;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const isID = !!(params?.methodId || params?.articleId);
  return (
    <nav
      className="hidden bg-transparent py-1 px-3 md:flex lg:py-3 lg:px-0"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 text-lg md:space-x-3">
        <li
          className="inline-flex cursor-pointer items-center border-l-4 border-secondary py-3"
          onClick={() => navigate(-1)}
        >
          <span
            className={classNames(
              isID ? "text-slate-500 hover:text-primary" : "text-default",
              "ml-1  text-sm md:ml-4"
            )}
          >
            {mainPage}
          </span>
        </li>
        <li className="py-3">
          <div className="flex items-center">
            <svg
              className="h-6 w-6 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <p
              //   to="#"
              className={classNames(
                isID ? "text-default" : "text-slate-500",
                "ml-1 text-sm md:ml-2"
              )}
            >
              {detailsPage}
            </p>
          </div>
        </li>
      </ol>
    </nav>
  );
};
